import { DataTableStore } from '../store/stateTypes';
import { classes } from '../utils/dataTableClasses';
import { hasControlPanelFromState } from '../utils/hasControlPanelFromState';

// Components
import { BulkEditButtonLayout } from '../bulkEdit/BulkEditButtonLayout/BulkEditButtonLayout';
import { BulkEditMenuLayout } from '../bulkEdit/BulkEditMenuLayout/BulkEditMenuLayout';
import { CustomBulkEditComponentWrapper } from '../bulkEdit/CustomBulkEditComponentWrapper';
import { EditTableButton } from './EditTableButton/EditTableButton';
import { ShowDeletedRowCheckbox } from './ShowDeletedRowCheckbox/ShowDeletedRowCheckbox';
import { ClientSideSearchBox } from './SearchBox/ClientSideSearchBox';
import { ServerSideSearchBox } from './SearchBox/ServerSideSearchBox';
import { ClearAllColumnFiltersButton } from './ClearAllColumnFiltersButton';
import { ColumnConfigButton } from './ColumnConfig/ColumnConfigButton';
import { TablePaginator } from './TablePaginator/TablePaginator';

type ControlPanelProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function ControlPanel<RowData>({ store }: ControlPanelProps<RowData>): JSX.Element {
  const hasControlPanel = store.useSelector((s) => hasControlPanelFromState(s));
  if (!hasControlPanel) {
    return <></>;
  }
  return (
    <div {...classes({ element: 'control_panel' })}>
      <div {...classes({ element: 'control_panel__section', modifiers: 'left' })}>
        {/* Bulk Edits */}
        <BulkEditButtonLayout store={store} />
        <BulkEditMenuLayout store={store} />
        <CustomBulkEditComponentWrapper store={store} />

        {/* Edit Table Button */}
        <EditTableButton store={store} />

        {/* Show Delete Row Checkbox */}
        <ShowDeletedRowCheckbox store={store} />

        {/* Search Box */}
        <ClientSideSearchBox store={store} />
        <ServerSideSearchBox store={store} />
      </div>

      <div {...classes({ element: 'control_panel__section', modifiers: 'right' })}>
        {/* Clear All Column Filters Button */}
        <ClearAllColumnFiltersButton store={store} />

        {/* Column Config Button */}
        <ColumnConfigButton store={store} />

        {/* Top-right Paginator */}
        <TablePaginator store={store} insideControlPanel={true} />
      </div>
    </div>
  );
}
