import { ReactElement, useContext } from 'react';
import { GroupBase, MenuListProps } from 'react-select';
import { FormFieldLayoutContext } from '../../FormFieldLayout/FormFieldLayoutContext';
import { classes } from '../Select';

const CustomMenuList = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: MenuListProps<Option, IsMulti, Group>
): ReactElement => {
  const { children, className, isMulti, innerRef, selectProps } = props;
  const listId = `select-list-${selectProps.instanceId}`;
  const layoutContext = useContext(FormFieldLayoutContext);
  return (
    <div
      {...classes({
        element: 'menu-list',
        modifiers: { [layoutContext?.layout || '']: true, 'is-multi': isMulti },
        extra: className,
      })}
      ref={innerRef}
      aria-expanded="true"
      role="listbox"
      id={listId}
    >
      {children}
    </div>
  );
};

export default CustomMenuList;
