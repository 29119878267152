import { CreateColumnCommonArgs, DataTableColumn } from '../../utils/internalTypes';
import Input from '../../../Input';
import { classes } from '../../utils/dataTableClasses';
import { createSortByDateString } from '../../sorts/createSortByDateString';
import { validate_row_data } from '../../store/actions/validate_row_data';

// https://day.js.org/docs/en/plugin/custom-parse-format
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const DAYJS_USE_STRICT_PARSING = true;

type CreateDateStringColumnArgs<RowData> = CreateColumnCommonArgs<RowData> & {
  dateFormat: string; // Forge Default: MM-DD-YYYY
};
export type CreateDateStringColumn<RowData> = typeof createDateStringColumn<RowData>;

export function createDateStringColumn<RowData>({
  columnId,
  tooltip,
  headerText,
  footer,
  cellValidation = () => '',
  determineCellAlert = () => '',
  displayCellText,
  rowDataFieldName,
  enableClientSideSorting = false,
  enableClientSideFullTextSearch = true,
  enableClientSideCsvDownload = true,
  isConfigurable = true,
  defaultHideColumn = false,
  //
  // additional args
  dateFormat,
}: CreateDateStringColumnArgs<RowData>): DataTableColumn<RowData> {
  const headerTextArray = typeof headerText === 'string' ? [headerText] : headerText;

  if (typeof displayCellText === 'undefined') {
    displayCellText = (row) => {
      // stringify the cell's data
      const cellContent = String(row[rowDataFieldName]);
      // format the data
      const date = dayjs(cellContent).format(dateFormat);

      if (date === 'Invalid Date' && cellContent === '') return '';
      return date;
    };
  }

  return {
    columnId,
    configOptionName: isConfigurable ? headerTextArray.join(' ') : '',
    defaultHideColumn,

    // ================================
    // Header
    // ================================
    headerAlignRight: false,
    headerTooltip: tooltip,
    Header: () => (
      <>
        {headerTextArray.map((txt, idx) => (
          <div key={idx}>{txt}</div>
        ))}
      </>
    ),

    // ================================
    // Footer
    // ================================
    Footer: footer,

    // ================================
    // Cell
    // ================================
    Cell: ({ useSelector, rowId }) => {
      const rowData = useSelector((s) => s.rows[rowId].data);
      const cellText = displayCellText ? displayCellText(rowData) : '';
      return <div style={{ whiteSpace: 'nowrap' }}>{cellText === '' ? '---' : cellText}</div>;
    },

    // ================================
    // EditCell
    // ================================
    EditCell: ({ useDispatch, useSelector, rowId }) => {
      const dispatch = useDispatch();
      const cellError = useSelector((s) => s.rows[rowId].cellErrors[columnId]);
      const originalValue = useSelector((s) => s.rows[rowId].originalData[rowDataFieldName]);
      const value = useSelector((s) => s.rows[rowId].data[rowDataFieldName]);
      if (typeof value !== 'string') throw new Error(`The value (${value}) should be string type.`);

      return (
        <Input
          {...classes({ element: 'td__input', modifiers: { unsaved: value !== originalValue } })}
          placeholder={dateFormat}
          value={value}
          error={cellError}
          onBlur={() => dispatch(validate_row_data(rowId))}
          onChange={(e) => {
            dispatch(function update_date_string_cell(s) {
              const value = e.target.value;

              // @ts-expect-error The field type has already been verified in runtime
              s.rows[rowId].data[rowDataFieldName] = value;
              s.rows[rowId].cellErrors[columnId] = ''; // reset cell error
            });
          }}
        />
      );
    },

    // ================================
    // Cell Alert
    // ================================
    determineCellAlert,

    // ================================
    // Data Validator
    // ================================
    dataValidator: (rowData) => {
      const fieldName = String(rowDataFieldName);
      const value = rowData[rowDataFieldName];

      if (typeof value !== 'string') return `The '${fieldName}' field should be string type.`;
      if (!dayjs(value, dateFormat, DAYJS_USE_STRICT_PARSING).isValid() && value !== '') {
        return `The '${fieldName}' field should be ${dateFormat} format.`;
      }
      return cellValidation(rowData);
    },

    // ================================
    // Sorting
    // ================================
    clientSideSortFn: enableClientSideSorting // prettier-break-line
      ? createSortByDateString({ rowDataFieldName })
      : undefined,

    // ================================
    // Full-Text Search
    // ================================
    clientSideFullTextSearch: {
      enable: enableClientSideFullTextSearch,
      getTextByRow: displayCellText,
    },

    // ================================
    // Download CSV
    // ================================
    clientSideCsvDownload: {
      enable: enableClientSideCsvDownload,
      headerText: headerTextArray.join(' '),
      getTextByRow: displayCellText,
    },
  };
}
