import AttentionLarge from '@athena/forge-icons/dist/AttentionLarge';
import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import CriticalLarge from '@athena/forge-icons/dist/CriticalLarge';
import { ForgeIconComponent } from '@athena/forge-icons/dist/ForgeIconProps';
import InfoLarge from '@athena/forge-icons/dist/InfoLarge';
import NewLarge from '@athena/forge-icons/dist/NewLarge';
import SuccessLarge from '@athena/forge-icons/dist/SuccessLarge';
import PropTypes from 'prop-types';
import React, { MouseEventHandler, ReactElement, ReactNode, WeakValidationMap, useCallback, useMemo } from 'react';
import Button, { ButtonProps } from '../Button';
import Heading from '../Heading';
import Tag from '../Tag';
import Overlay, { OverlayProps } from '../Overlay';
import { themeContextPropTypes, useTheme } from '../Root/ThemeContext';
import { Theme } from '../Root/context';
import { forgeClassHelper } from '../utils/classes';
import { AlertTypes } from '../utils/constants';
import { isUndefined } from '../utils/helpers';
import { DistributiveOmit } from '../utils/types';

const classes = forgeClassHelper({ name: 'modal', isPortal: true });

export type ModalWidth = 'small' | 'medium' | 'large' | 'full';
export type ModalHeight = 'medium' | 'full' | 'auto';
export type ModalButton = DistributiveOmit<ButtonProps<true> | ButtonProps<false>, 'autoFocus' | 'variant'>[];

export type ModalAutoFocusTarget = 'closeIcon' | 'headerButton' | 'primaryButton' | 'secondaryButton' | 'none';

export interface ModalDisableClose {
  disableClose: true;
  onHide?: () => void;
}

export interface ModalNotDisableClose {
  disableClose?: false;
  onHide: () => void;
}

export interface ModalBaseProps extends OverlayProps {
  /** Gives a default left border and header with icon that corresponds to status level */
  alertType?: AlertTypes;
  /** Determine autoFocus target Button element */
  autoFocusTarget?: ModalAutoFocusTarget;
  /** Content to display in modal */
  children?: React.ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Close button aria-label */
  closeButtonAriaLabel?: string;
  /** Remove close button and background close clicking */
  disableClose?: boolean;
  /** Array of secondary buttons to be added inline in the footer
   *
   * This type defaults useLink=false, because autoFocus doesn't exist for
   * anchor elements. */
  footerSecondaryButtons?: ModalButton;
  /** Array of tertiary buttons to be added inline in the footer
   *
   * This type defaults useLink=false, because autoFocus doesn't exist for
   * anchor elements. */
  footerTertiaryButtons?: ModalButton;
  /** Array of buttons to be added inline in the header
   *
   * This type defaults useLink=false, because autoFocus doesn't exist for
   * anchor elements. */
  headerButtons?: ModalButton;
  /** Title text displayed at the top of the modal */
  headerText: string;
  /** Hides borders that divide the header, body, and footer sections */
  hideDividers?: boolean;
  /** Includes default button row */
  includeButtonRow?: boolean;
  /** Adds a class to the content wrapper for the left panel */
  leftClassName?: string;
  /** Title text displayed at the top of the Modal's left panel. */
  leftHeaderText?: string;
  /** leftIndicatorText text displayed at the top right of the Modal's left panel. */
  leftIndicatorText?: string;
  /** Contents of the left panel */
  leftPanelChildren?: ReactNode;
  /** Callback fired when the primary button is clicked */
  onPrimaryClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  /** If set to true, will provide default padding for content */
  padded?: boolean;
  /** Text for the primary action button */
  primaryButtonText?: string;
  /** Boolean to disable the primary button */
  primaryButtonDisabled?: boolean;
  /** Adds a class to the content wrapper for the default or right panel */
  rightClassName?: string;
  /**  Set the visibility of the Modal */
  show?: boolean;
  /** Status text displayed at the top of the component. */
  status?: string;
  /** Theme variables for configuring an instance of Forge. See Root component for more details. */
  theme?: Theme;
  /** Width of modal dialog box, LeftPanel only allowed under large or full width */
  width?: ModalWidth;
  /** Height of the Modal. */
  height?: ModalHeight;
  /** Modal content style presets, left panel only allowed under complex */
  contentStyle?: 'simple' | 'complex';
  /** Footer button group alignment, when contentStyle is 'simple' default to 'center'
   * when contentStyle is 'complex' default to 'right' */
  footerAlignment?: 'center' | 'right';
}

interface LeftPanelProps {
  children: ReactNode;
  className?: string;
  height: ModalHeight;
  headerText: string;
  leftIndicatorText?: string;
  padded: boolean;
}
interface RightPanelProps extends ModalBaseProps {
  handleOnHide?: () => void;
}

export type ModalProps = (ModalDisableClose | ModalNotDisableClose) & ModalBaseProps;

const getAlertIcon = (alertType?: AlertTypes): ForgeIconComponent | null => {
  switch (alertType) {
    case 'attention':
      return AttentionLarge;
    case 'critical':
      return CriticalLarge;
    case 'info':
      return InfoLarge;
    case 'new':
      return NewLarge;
    case 'success':
      return SuccessLarge;
    default:
      return null;
  }
};

const Modal = ({
  alertType,
  autoFocusTarget = 'none',
  children,
  className,
  closeButtonAriaLabel = 'Close Modal',
  contentStyle = 'simple',
  disableClose = false,
  footerSecondaryButtons,
  footerTertiaryButtons,
  footerAlignment: upstreamFooterAlignment,
  headerButtons,
  headerText,
  leftClassName,
  leftIndicatorText,
  leftHeaderText = '',
  leftPanelChildren: upstreamLeftPanelChildren,
  height: upstreamHeight,
  hideDividers = false,
  includeButtonRow = true,
  onHide,
  onPrimaryClick,
  padded = true,
  primaryButtonText = 'OK',
  primaryButtonDisabled = false,
  rightClassName,
  show = false,
  status,
  theme: upstreamTheme,
  width: upstreamWidth,
  ...rest
}: ModalProps): ReactElement => {
  const theme = useTheme(upstreamTheme);
  const leftPanelChildren = contentStyle === 'complex' ? upstreamLeftPanelChildren : undefined;
  const reconcileWidth = (): ModalWidth => {
    if (leftPanelChildren && (!upstreamWidth || upstreamWidth === 'medium' || upstreamWidth === 'small')) {
      return 'large';
    } else if (!upstreamWidth) {
      return 'small';
    }
    return upstreamWidth;
  };
  const width = reconcileWidth();

  const footerAlignment = upstreamFooterAlignment
    ? upstreamFooterAlignment
    : contentStyle === 'simple'
      ? 'center'
      : 'right';
  const reconcileHeight = (): ModalHeight => {
    if (!upstreamHeight) {
      return leftPanelChildren ? 'medium' : 'auto';
    }
    return upstreamHeight;
  };
  const height = reconcileHeight();
  const heightKey = `${height}_height`;

  const handleOnHide = useCallback((): void => {
    if (onHide && !disableClose) {
      onHide();
    }
  }, [onHide, disableClose]);

  const modalRightPanel = (
    <ModalRightPanel
      alertType={alertType}
      className={rightClassName}
      closeButtonAriaLabel={closeButtonAriaLabel}
      disableClose={disableClose}
      autoFocusTarget={autoFocusTarget}
      footerAlignment={footerAlignment}
      footerSecondaryButtons={footerSecondaryButtons}
      footerTertiaryButtons={footerTertiaryButtons}
      height={height}
      handleOnHide={handleOnHide}
      headerButtons={headerButtons}
      headerText={headerText}
      includeButtonRow={includeButtonRow}
      onPrimaryClick={onPrimaryClick}
      padded={padded}
      primaryButtonText={primaryButtonText}
      primaryButtonDisabled={primaryButtonDisabled}
      status={status}
    >
      {children}
    </ModalRightPanel>
  );

  return (
    <Overlay
      onHide={handleOnHide}
      show={show}
      {...rest}
      dialogClass={classes({
        element: 'dialog',
        modifiers: {
          [`${alertType}`]: !!alertType,
          [width]: true,
          [heightKey]: true,
          'without-dividers': hideDividers,
        },
      })}
      {...classes({
        extra: className,
        modifiers: { [`${alertType}`]: !!alertType, [width]: true },
        theme,
      })}
    >
      {leftPanelChildren && (
        <div {...classes({ element: 'row' })}>
          <div {...classes({ element: 'panels', modifiers: 'left' })}>
            <ModalLeftPanel
              className={leftClassName}
              height={height}
              headerText={leftHeaderText}
              leftIndicatorText={leftIndicatorText}
              padded={padded}
            >
              {leftPanelChildren}
            </ModalLeftPanel>
          </div>
          <div {...classes({ element: 'panels', modifiers: 'right' })}>{modalRightPanel}</div>
        </div>
      )}
      {!leftPanelChildren && modalRightPanel}
    </Overlay>
  );
};

const ModalLeftPanel = ({
  children,
  className,
  headerText,
  leftIndicatorText,
  padded,
}: LeftPanelProps): JSX.Element => {
  return (
    <>
      <div {...classes({ element: 'header-left' })}>
        <Heading text={headerText} headingTag={'h2'} variant={'subsection'} />
        {leftIndicatorText && <span>{leftIndicatorText}</span>}
      </div>
      <div
        {...classes({
          element: 'body',
          modifiers: { padded: padded },
          extra: className,
        })}
      >
        {children}
      </div>
    </>
  );
};

const ModalRightPanel = ({
  alertType,
  children,
  closeButtonAriaLabel,
  disableClose,
  autoFocusTarget,
  footerAlignment,
  footerSecondaryButtons,
  footerTertiaryButtons,
  handleOnHide,
  headerButtons,
  headerText,
  includeButtonRow,
  onPrimaryClick,
  padded,
  primaryButtonDisabled,
  primaryButtonText,
  status,
}: RightPanelProps): JSX.Element => {
  const AlertIcon = useMemo(() => getAlertIcon(alertType), [alertType]);
  const footerKey = `${footerAlignment}_aligned`;
  return (
    <>
      <div
        {...classes({
          element: 'header',
          modifiers: { 'without-close': !!disableClose },
        })}
      >
        {AlertIcon && <AlertIcon {...classes({ element: 'icon' })} />}
        {!status ? (
          <Heading text={headerText} headingTag={'h1'} variant={'section'} />
        ) : (
          <div {...classes({ element: 'header-text-with-tag' })}>
            <Heading text={headerText} headingTag={'h1'} variant={'section'} />
            <div>
              <Tag text={status} isRemovable={false} />
            </div>
          </div>
        )}
        {headerButtons &&
          headerButtons.map((button, i) => (
            <Button key={i} {...button} autoFocus={autoFocusTarget === 'headerButton' && i === 0} variant="tertiary" />
          ))}
        {!disableClose && (
          <Button
            aria-label={closeButtonAriaLabel}
            autoFocus={autoFocusTarget === 'closeIcon'}
            icon={CloseSmall}
            onClick={handleOnHide}
            size="small"
            variant="tertiary"
          />
        )}
      </div>
      {children && <div {...classes({ element: 'body', modifiers: { padded: !!padded } })}>{children}</div>}
      {includeButtonRow && (
        <div {...classes({ element: 'footer', modifiers: { [footerKey]: true } })}>
          {footerTertiaryButtons &&
            footerTertiaryButtons.map((button, i) => <Button key={'tertiary' + i} {...button} variant="tertiary" />)}
          {footerSecondaryButtons &&
            footerSecondaryButtons.map((button, i) => (
              <Button
                key={'secondary' + i}
                {...button}
                autoFocus={autoFocusTarget === 'secondaryButton' && i === 0}
                variant="secondary"
              />
            ))}

          <Button
            text={primaryButtonText}
            onClick={onPrimaryClick}
            disabled={primaryButtonDisabled}
            autoFocus={autoFocusTarget === 'primaryButton'}
          />
        </div>
      )}
    </>
  );
};

const ModalPropTypes: WeakValidationMap<ModalBaseProps & { '...rest': unknown }> = {
  alertType: PropTypes.oneOf<AlertTypes>(['attention', 'critical', 'info', 'new', 'success']),
  autoFocusTarget: PropTypes.oneOf(['closeIcon', 'headerButton', 'primaryButton', 'secondaryButton', 'none']),
  children: PropTypes.node,
  className: PropTypes.string,
  closeButtonAriaLabel: PropTypes.string,
  disableClose: PropTypes.bool,
  headerText: PropTypes.string.isRequired,
  height: PropTypes.oneOf(['medium', 'full', 'auto']),
  includeButtonRow: PropTypes.bool,
  onEnter: PropTypes.func,
  onEntered: PropTypes.func,
  onEntering: PropTypes.func,
  onExit: PropTypes.func,
  onExited: PropTypes.func,
  onExiting: PropTypes.func,
  onHide: function (props, propName, ...rest) {
    if (!props['disableClose'] && isUndefined(props[propName])) {
      return new Error('onHide is required to close modal');
    }
    return PropTypes.func(props, propName, ...rest);
  },
  onPrimaryClick: PropTypes.func,
  onShow: PropTypes.func,
  padded: PropTypes.bool,
  primaryButtonText: PropTypes.string,
  show: PropTypes.bool,
  status: PropTypes.string,
  theme: themeContextPropTypes,
  width: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
  '...rest': PropTypes.any,
};

Modal.propTypes = ModalPropTypes;

export default Modal;
