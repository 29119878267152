import Input from '../../../Input';
import { createSortByText } from '../../sorts/createSortByText';
import { validate_row_data } from '../../store/actions/validate_row_data';
import { classes } from '../../utils/dataTableClasses';
import { DataTableColumn, CreateColumnCommonArgs } from '../../utils/internalTypes';

export type CreateTextColumn<RowData> = typeof createTextColumn<RowData>;

export function createTextColumn<RowData>({
  columnId,
  tooltip,
  headerText,
  footer,
  cellValidation = () => '',
  determineCellAlert = () => '',
  displayCellText,
  rowDataFieldName,
  enableClientSideSorting = false,
  enableClientSideFullTextSearch = true,
  enableClientSideCsvDownload = true,
  isConfigurable = true,
  defaultHideColumn = false,
}: CreateColumnCommonArgs<RowData>): DataTableColumn<RowData> {
  const headerTextArray = typeof headerText === 'string' ? [headerText] : headerText;

  if (typeof displayCellText === 'undefined') {
    displayCellText = (row) => String(row[rowDataFieldName]);
  }

  return {
    columnId,
    configOptionName: isConfigurable ? headerTextArray.join(' ') : '',
    defaultHideColumn,

    // ================================
    // Header
    // ================================
    headerAlignRight: false,
    headerTooltip: tooltip,
    Header: () => (
      <>
        {headerTextArray.map((txt, idx) => (
          <div key={idx}>{txt}</div>
        ))}
      </>
    ),

    // ================================
    // Footer
    // ================================
    Footer: footer,

    // ================================
    // Cell
    // ================================
    Cell: ({ useSelector, rowId }) => {
      const rowData = useSelector((s) => s.rows[rowId].data);
      const cellText = displayCellText ? displayCellText(rowData) : '';
      return <div>{cellText === '' ? '---' : cellText}</div>;
    },

    // ================================
    // EditCell
    // ================================
    EditCell: ({ useDispatch, useSelector, rowId }) => {
      const dispatch = useDispatch();
      const cellError = useSelector((s) => s.rows[rowId].cellErrors[columnId]);
      const originalValue = useSelector((s) => s.rows[rowId].originalData[rowDataFieldName]);
      const value = useSelector((s) => s.rows[rowId].data[rowDataFieldName]);
      if (typeof value !== 'string') throw new Error(`The value (${value}) should be string type.`);

      return (
        <Input
          {...classes({ element: 'td__input', modifiers: { unsaved: value !== originalValue } })}
          value={value}
          error={cellError}
          onBlur={() => dispatch(validate_row_data(rowId))}
          onChange={(e) => {
            dispatch(function update_text_cell(s) {
              const value = e.target.value;

              // @ts-expect-error The field type has already been verified in runtime
              s.rows[rowId].data[rowDataFieldName] = value;
              s.rows[rowId].cellErrors[columnId] = ''; // reset cell error
            });
          }}
        />
      );
    },

    // ================================
    // Cell Alert
    // ================================
    determineCellAlert,

    // ================================
    // Data Validator
    // ================================
    dataValidator: (rowData) => {
      const fieldName = String(rowDataFieldName);
      const value = rowData[rowDataFieldName];

      if (typeof value !== 'string') return `The '${fieldName}' field should be string type.`;

      return cellValidation(rowData);
    },

    // ================================
    // Sorting
    // ================================
    clientSideSortFn: enableClientSideSorting
      ? createSortByText({ rowDataFieldName, caseSensitive: false })
      : undefined,

    // ================================
    // Full-Text Search
    // ================================
    clientSideFullTextSearch: {
      enable: enableClientSideFullTextSearch,
      getTextByRow: displayCellText,
    },

    // ================================
    // Download CSV
    // ================================
    clientSideCsvDownload: {
      enable: enableClientSideCsvDownload,
      headerText: headerTextArray.join(' '),
      getTextByRow: displayCellText,
    },
  };
}
