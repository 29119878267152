import Heading from '../../Heading';
import { DataTableStore } from '../store/stateTypes';
import { classes } from '../utils/dataTableClasses';
import { getRowCountFromState } from '../utils/getRowCountFromState';
import { ServerSideRefreshButton } from './ServerSideRefreshButton/ServerSideRefreshButton';
import { ClientSideDownloadCsvButton } from './DownloadCsvButton/ClientSideDownloadCsvButton';
import { ServerSideDownloadCsvButton } from './DownloadCsvButton/ServerSideDownloadCsvButton';

// https://day.js.org/docs/en/plugin/timezone
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.tz.guess();

type TableTitlePanelProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function TableTitlePanel<RowData>({ store }: TableTitlePanelProps<RowData>): JSX.Element {
  const tableTitle = store.useSelector((s) => s.tableTitle);
  const tableType = store.useSelector((s) => s.tableType);
  const csvDownload = store.useSelector((s) => s.csvDownload);
  const rowCount = store.useSelector((s) => getRowCountFromState(s));
  const lastDataUpdateTimeInTableTitle = store.useSelector((s) => s.lastDataUpdateTimeInTableTitle);
  const showServerSideRefreshButton = store.useSelector((s) => s.showServerSideRefreshButton);
  const entityName = store.useSelector((s) => s.entityName);
  const CustomAddRowComponent = store.useSelector((s) => s.CustomAddRowComponent);

  const hasCustomAddRowComponent = typeof CustomAddRowComponent !== 'undefined';
  const hasTableTitleOrUpdateTime =
    entityName.showInTableTitle || lastDataUpdateTimeInTableTitle.show || showServerSideRefreshButton;
  const isSingleLine = !hasCustomAddRowComponent && !hasTableTitleOrUpdateTime;

  // No Title Panel
  if (isSingleLine && tableTitle.length === 0 && !csvDownload.showButton) {
    return <></>;
  }

  return (
    <div {...classes({ element: 'table_title_panel', modifiers: { 'single-line': isSingleLine } })}>
      {/* Left Section: Table Heading, Entity Name, Date Time, and Custom Add Row Panel */}
      <div {...classes({ element: 'table_title_panel__left_section' })}>
        {/* Table Heading */}
        {tableTitle.length > 0 && <Heading headingTag="h3" variant="section" text={tableTitle} />}

        {/* Entity Name and Update Time */}
        {hasTableTitleOrUpdateTime && (
          <div {...classes({ element: 'table_title_panel__entity_name_and_update_time' })}>
            {/* Entity Name */}
            {entityName.showInTableTitle && (
              <div {...classes({ element: 'table_title_panel__entity_name' })}>
                <div>{entityName.prefix}</div>
                <div>{rowCount.toLocaleString()}</div>
                <div>{rowCount === 1 ? entityName.singular : entityName.plural}</div>
              </div>
            )}

            {/* Update Time */}
            {lastDataUpdateTimeInTableTitle.show && (
              <div {...classes({ element: 'table_title_panel__update_time' })}>
                {dayjs(lastDataUpdateTimeInTableTitle.updateTime).format('[as of] MM-DD-YYYY [at] hh:mm a z')}
              </div>
            )}

            {showServerSideRefreshButton && tableType === 'server-side' && (
              <div>
                <ServerSideRefreshButton store={store} />
              </div>
            )}
          </div>
        )}
        {/* Custom Add Row Panel */}
        <div {...classes({ element: 'table_title_panel__custom_add_row_panel' })}>
          {hasCustomAddRowComponent && (
            <CustomAddRowComponent
              useSelector={store.useSelector}
              useDispatch={store.useDispatch}
              useDispatchAsyncAction={store.useDispatchAsyncAction}
            />
          )}
        </div>
      </div>

      {/* Right Section: Download CSV Button */}
      <div {...classes({ element: 'table_title_panel__right_section' })}>
        {tableType === 'client-side' && <ClientSideDownloadCsvButton store={store} />}
        {tableType === 'server-side' && typeof csvDownload.fetchCSV !== 'undefined' && (
          <ServerSideDownloadCsvButton store={store} />
        )}
      </div>
    </div>
  );
}
