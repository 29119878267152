import dayjs from 'dayjs';
import DateInput, { DateInputValue } from '../../../DateInput';
import { createSortByDateString } from '../../sorts/createSortByDateString';
import { validate_row_data } from '../../store/actions/validate_row_data';
import { classes } from '../../utils/dataTableClasses';
import { DataTableColumn, CreateColumnCommonArgs } from '../../utils/internalTypes';

export type CreateDateInputColumn<RowData> = typeof createDateInputColumn<RowData>;

export function createDateInputColumn<RowData>({
  columnId,
  tooltip,
  headerText,
  footer,
  cellValidation = () => '',
  determineCellAlert = () => '',
  displayCellText,
  rowDataFieldName,
  enableClientSideSorting = false,
  enableClientSideFullTextSearch = true,
  enableClientSideCsvDownload = true,
  isConfigurable = true,
  defaultHideColumn = false,
}: CreateColumnCommonArgs<RowData>): DataTableColumn<RowData> {
  const headerTextArray = typeof headerText === 'string' ? [headerText] : headerText;

  if (typeof displayCellText === 'undefined') {
    displayCellText = (row) => {
      const cellData = row[rowDataFieldName];

      return cellData instanceof Date ? dayjs(cellData).format('MM-DD-YYYY') : '';
    };
  }

  return {
    columnId,
    configOptionName: isConfigurable ? headerTextArray.join(' ') : '',
    defaultHideColumn,

    // ================================
    // Header
    // ================================
    headerAlignRight: false,
    headerTooltip: tooltip,
    Header: () => (
      <>
        {headerTextArray.map((txt, idx) => (
          <div key={idx}>{txt}</div>
        ))}
      </>
    ),

    // ================================
    // Footer
    // ================================
    Footer: footer,

    // ================================
    // Cell
    // ================================
    Cell: ({ useSelector, rowId }) => {
      const rowData = useSelector((s) => s.rows[rowId].data);
      const cellText = displayCellText ? displayCellText(rowData) : '';
      return <div style={{ whiteSpace: 'nowrap' }}>{cellText === '' ? '---' : cellText}</div>;
    },

    // ================================
    // EditCell
    // ================================
    EditCell: ({ useDispatch, useSelector, rowId }) => {
      const dispatch = useDispatch();
      const cellError = useSelector((s) => s.rows[rowId].cellErrors[columnId]);
      const originalValue = useSelector((s) => s.rows[rowId].originalData[rowDataFieldName]);
      const value = useSelector((s) => s.rows[rowId].data[rowDataFieldName]) as DateInputValue;
      if (!(value instanceof Date) && value !== null) {
        throw new Error(`The value (${value}) should be date type or null.`);
      }

      return (
        <DateInput
          {...classes({ element: 'td__input', modifiers: { unsaved: value !== originalValue } })}
          value={value}
          error={cellError}
          onBlur={() => dispatch(validate_row_data(rowId))}
          onChange={(e) => {
            dispatch(function update_date_cell(s) {
              const value = e.target.value;

              if (value !== null && !(value instanceof Date)) {
                throw new Error(`The value (${value}) should be date type or null.`);
              }

              // @ts-expect-error The field type has already been verified in runtime
              s.rows[rowId].data[rowDataFieldName] = value;
              s.rows[rowId].cellErrors[columnId] = ''; // reset cell error
            });
          }}
        />
      );
    },

    // ================================
    // Cell Alert
    // ================================
    determineCellAlert,

    // ================================
    // Data Validator
    // ================================
    dataValidator: (rowData) => {
      const value = rowData[rowDataFieldName];
      if (value !== null && !(value instanceof Date) && value !== '') {
        throw new Error(`The value (${value}) should be date type or null.`);
      }

      return cellValidation(rowData);
    },

    // ================================
    // Sorting
    // ================================
    clientSideSortFn: enableClientSideSorting // prettier-break-line
      ? createSortByDateString({ rowDataFieldName })
      : undefined,

    // ================================
    // Full-Text Search
    // ================================
    clientSideFullTextSearch: {
      enable: enableClientSideFullTextSearch,
      getTextByRow: displayCellText,
    },

    // ================================
    // Download CSV
    // ================================
    clientSideCsvDownload: {
      enable: enableClientSideCsvDownload,
      headerText: headerTextArray.join(' '),
      getTextByRow: displayCellText,
    },
  };
}
