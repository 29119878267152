import FormError from '../../FormError';
import { classes } from '../utils/dataTableClasses';
import { DataTableState, DataTableStore } from '../store/stateTypes';
import { useContextMenu } from 'react-contexify';
import { getContextMenuId } from '../utils/getContextMenuId';
import { ExpandedTableRowWrapper } from './ExpandedTableRowWrapper';
import Tooltip from '../../Tooltip';
import AttentionSmall from '@athena/forge-icons/dist/AttentionSmall';

type TableRowProps<RowData> = {
  store: DataTableStore<RowData>;
  rowId: number;
};

export function TableRow<RowData>({ store, rowId }: TableRowProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();
  const tableId = store.useSelector((s) => s.tableId);
  const contextMenu = useContextMenu({ id: getContextMenuId(tableId) });
  const isContextMenuEnable = store.useSelector((s) => s.contextMenu.enable);
  const onRowClickLeft = store.useSelector((s) => s.onRowClickLeft);
  const grayDeletedRows = store.useSelector((s) => s.grayDeletedRows);
  const rowObject = store.useSelector((s) => s.rows[rowId]);
  const { isSelected, isExpanded, rowStatus, cellErrors } = rowObject;

  // columns
  const columns = store.useSelector((s) => s.columns);
  const columnIds = store.useSelector((s) => s.columnIds);
  const visibleColumnIds = columnIds.filter((cid) => !columns[cid].hide);

  // Get custom table row props
  const getCustomRowProps = store.useSelector((s) => s.onRenderRowProps);
  const { className = '', ...customTableRowProps } = getCustomRowProps?.(rowObject) ?? {};

  return (
    <>
      <tr
        {...classes({
          element: 'tr',
          modifiers: {
            view: rowStatus === 'view',
            edit: rowStatus === 'edit',
            deleted: rowStatus === 'deleted',
            selected: isSelected,
            'expanded-top-row': isExpanded,
            // if 'grayDeletedRows' is set to false, display deleted rows without a background color.
            'deleted-transparent': rowStatus === 'deleted' && !grayDeletedRows,
          },
          extra: className, // custom table row className
        })}
        {...customTableRowProps}
      >
        {visibleColumnIds.map((cid) => {
          const { columnId, cellStyle, cellClassName, Cell, EditCell, DeleteCell, determineCellAlert } = columns[cid];
          const cell = {
            key: `${tableId}-tr-${rowId}-td-${columnId}`,
            style: cellStyle?.(rowObject),
            className: cellClassName?.(rowObject),
            alertText: determineCellAlert?.(rowObject.data) ?? '',
          };

          // ========================
          // Row Left Click Handler
          // ========================
          const rowLeftClickHandler = (e: React.MouseEvent<HTMLTableCellElement>): void => {
            if (typeof onRowClickLeft === 'undefined') return;

            // Toggle Select Row
            if (onRowClickLeft === 'toggle-select-row') {
              dispatch(function left_click_toggle_select_row(s) {
                const isSelectable = s.determineSelectableRow(s.rows[rowId].data);
                if (isSelectable) {
                  s.rows[rowId].isSelected = !s.rows[rowId].isSelected;
                }
              });
              return;
            }

            // Toggle Expand Row
            if (onRowClickLeft === 'toggle-expand-row') {
              dispatch(function left_click_toggle_expand_row(s) {
                const isRowExpandable = s.determineExpandableRow(s.rows[rowId].data);
                if (isRowExpandable) {
                  const { isExpanded } = s.rows[rowId];
                  s.rows[rowId].isExpanded = !isExpanded;
                }
              });
              return;
            }

            // custom action
            dispatch(onRowClickLeft(rowId));
          };

          // ========================
          // Show context menu in 'view' and 'deleted' row status
          // ========================
          const showContextMenu = (event: React.MouseEvent): void => {
            if (isContextMenuEnable) {
              dispatch(function right_click_open_context_menu(s) {
                s.contextMenu.rowId = rowId;
                s.contextMenu.columnId = columnId;
              });
              const props: Omit<DataTableState<RowData>['contextMenu'], 'enable'> = { rowId, columnId };
              contextMenu.show({ event, props });
            }
          };

          // Edit Status
          if (rowStatus === 'edit') {
            return (
              <td
                key={cell.key + '-edit'}
                data-column={columnId}
                style={cell.style}
                {...classes({
                  element: 'td',
                  modifiers: 'edit',
                  extra: cell.className,
                })}
              >
                <EditCell
                  rowId={rowId}
                  useSelector={store.useSelector}
                  useDispatch={store.useDispatch}
                  useDispatchAsyncAction={store.useDispatchAsyncAction}
                />
                {!!cellErrors[columnId] && <FormError>{cellErrors[columnId]}</FormError>}
              </td>
            );
          }

          // Delete Status
          if (rowStatus === 'deleted') {
            return (
              <td
                key={cell.key + '-deleted'}
                data-column={columnId}
                style={cell.style}
                {...classes({
                  element: 'td',
                  modifiers: 'deleted',
                  extra: cell.className,
                })}
                onClick={rowLeftClickHandler}
                onContextMenu={showContextMenu}
              >
                <DeleteCell
                  rowId={rowId}
                  useSelector={store.useSelector}
                  useDispatch={store.useDispatch}
                  useDispatchAsyncAction={store.useDispatchAsyncAction}
                />
              </td>
            );
          }

          // View Status
          return (
            <td
              key={cell.key}
              data-column={columnId}
              style={cell.style}
              {...classes({
                element: 'td',
                modifiers: 'view',
                extra: cell.className,
              })}
              onClick={rowLeftClickHandler}
              onContextMenu={showContextMenu}
            >
              <div {...classes({ element: 'td__view-inner' })} style={cell.style}>
                {cell.alertText !== '' && (
                  <Tooltip
                    id={cell.key + '-cell-alert'}
                    wrapperClassName={classes({ element: 'td__view-inner__cell-alert' }).className}
                    text={cell.alertText}
                  >
                    <AttentionSmall title="" aria-label="cell-alert" />
                  </Tooltip>
                )}

                <div data-column={columnId} style={cell.style} {...classes({ element: 'td__view-inner__cell' })}>
                  <Cell
                    rowId={rowId}
                    useSelector={store.useSelector}
                    useDispatch={store.useDispatch}
                    useDispatchAsyncAction={store.useDispatchAsyncAction}
                  />
                </div>
              </div>
            </td>
          );
        })}
      </tr>

      {/* Expanded Row */}
      <ExpandedTableRowWrapper store={store} rowId={rowId} />
    </>
  );
}
