import { HTMLAttributes, ReactElement, ReactNode, Ref } from 'react';
import { forgeClassHelper } from '../utils/classes';
import AttentionSmall from '@athena/forge-icons/dist/AttentionSmall';
import CriticalSmall from '@athena/forge-icons/dist/CriticalSmall';
import forwardRefToProps from '../utils/forwardRefToProps';

const classes = forgeClassHelper('form-error');
const statusIconMap = {
  attention: AttentionSmall,
  critical: CriticalSmall,
};

export type FormErrorAlertType = 'attention' | 'critical';

export interface FormErrorProps extends HTMLAttributes<HTMLDivElement> {
  /** DOM id to refer to to the error message */
  id?: string;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Styles to the type of alert */
  alertType?: FormErrorAlertType;
  /** Content to appear inside of Form Error */
  children: ReactNode;
  /** Ref to top-level div */
  ref?: Ref<HTMLDivElement>;
}

interface FormErrorComponentProps extends FormErrorProps {
  /** Ref to top-level div */
  forwardedRef?: Ref<HTMLDivElement>;
}

/** Displays an error message in conjunction with a FormField. */
const FormError = ({
  alertType = 'attention',
  children,
  className,
  forwardedRef,
  id,
  ...rest
}: FormErrorComponentProps): ReactElement => {
  let IconComponent;
  if (alertType) {
    IconComponent = statusIconMap[alertType];
  }

  return (
    <div
      ref={forwardedRef}
      {...rest}
      {...classes({
        modifiers: alertType,
        extra: className,
      })}
    >
      {IconComponent && <IconComponent {...classes('icon')} />}
      <div id={id} {...classes('content')}>
        {children}
      </div>
    </div>
  );
};
FormError.displayName = 'FormError';

export default forwardRefToProps(FormError);
