import { useCallback, ReactElement, useContext, WeakValidationMap, Ref, useMemo } from 'react';
import PropTypes, { Validator } from 'prop-types';
import { forgeClassHelper } from '../utils/classes';
import FormFieldLayout, { FormFieldLayoutProps } from '../FormFieldLayout';
import InlineMessage from '../InlineMessage';
import Label from '../Label';
import FormError from '../FormError';
import { FormContext, FormContextData } from '../Form/FormContext';
import MultiFieldFieldComponent from './MultiFieldFieldComponent';
import { FormFieldBaseInputProps } from '../FormField/FormFieldTypes';
import forwardRefToProps from '../utils/forwardRefToProps';
import { optionalBoolValidator, optionalStringValidator } from '../utils/betterPropTypes';
import { nanoid } from 'nanoid';

const classes = forgeClassHelper({ name: 'multi-field' });
const formFieldClasses = forgeClassHelper({ name: 'form-field' });

/** The type for an individual item within `fields`
 *
 * extending undefined shouldn't be necessary, but is required for MultiFieldFields
 * to play nice.
 */
export type MultiFieldField<T extends FormFieldBaseInputProps | undefined> = T & {
  id: string;
  inputAs?: React.ComponentType<T>;
  labelText?: string;
};

/** Base props supported by an inputAs component when using flexible typing.
 *
 * Not exported because the only use-case outside of this file would be
 * to provide this as an explicit type parameter, such as
 * MultiField<FlexibleMultiFieldInputAsProps>. This is an anti-pattern, as
 * if a tuple type is being used, you may as well provide a strong type.
 */
type FlexibleMultiFieldInputAsProps = FormFieldBaseInputProps & Record<string, unknown>;

/** The type of the `fields` prop when not using tuples.
 *
 * Useful for defining `const fields: FlexibleMultiFieldInputAsProps = [...]`.
 * Also useful within MultiField implementation as a fall back for when a tuple
 * type cannot be inferred.
 */
export type FlexibleMultiFieldFields = Array<MultiFieldField<FlexibleMultiFieldInputAsProps>>;

/** Type for `fields` prop.
 *
 * Cascading ternary compile-time checks allow MultiField to be supplied with up
 * to 5 explicit type parameters, while falling back to a flexible type definition
 * for the 6th field and beyond.
 */
export type MultiFieldFields<
  F1 extends FormFieldBaseInputProps | undefined,
  F2 extends FormFieldBaseInputProps | undefined,
  F3 extends FormFieldBaseInputProps | undefined,
  F4 extends FormFieldBaseInputProps | undefined,
  F5 extends FormFieldBaseInputProps | undefined
> = F1 extends undefined
  ? FlexibleMultiFieldFields
  : F2 extends undefined
  ? [MultiFieldField<F1>]
  : F3 extends undefined
  ? [MultiFieldField<F1>, MultiFieldField<F2>]
  : F4 extends undefined
  ? [MultiFieldField<F1>, MultiFieldField<F2>, MultiFieldField<F3>]
  : F5 extends undefined
  ? [MultiFieldField<F1>, MultiFieldField<F2>, MultiFieldField<F3>, MultiFieldField<F4>]
  : /** Either we have been supplied 5 explicit type parameters or `fields` is
     * being supplied as a const. In the former case, we want the
     * type to be a tuple with 5 strongly typed entries. In the later case,
     * we need to default to a flexibly typed array of any length */
    | [
          MultiFieldField<F1>,
          MultiFieldField<F2>,
          MultiFieldField<F3>,
          MultiFieldField<F4>,
          MultiFieldField<F5>,
          ...FlexibleMultiFieldFields
        ]
      | FlexibleMultiFieldFields;

/** The props accepted by MultiField.
 *
 * Defaulting generic types to `undefined` allows MultiField to be supplied with up
 * to 5 explicit type parameters, while falling back to a flexible type definition
 * for the 6th field and beyond.
 */
export type MultiFieldProps<
  F1 extends FormFieldBaseInputProps | undefined = undefined,
  F2 extends FormFieldBaseInputProps | undefined = undefined,
  F3 extends FormFieldBaseInputProps | undefined = undefined,
  F4 extends FormFieldBaseInputProps | undefined = undefined,
  F5 extends FormFieldBaseInputProps | undefined = undefined
> = Omit<
  FormFieldLayoutProps,
  'statusExtras' | 'useFieldset' | 'labelSlot' | 'messageSlot' | 'statusIndicatorSlot' | 'inputSlot'
> & {
  disabled?: boolean;
  fields: MultiFieldFields<F1, F2, F3, F4, F5>;
  hideRequiredStyles?: boolean;
  hintText?: string;
  isLabelTextError?: boolean;
  labelAlwaysAbove?: boolean;
  labelText?: string;
  required?: boolean;
};

type MultiFieldComponentProps<
  F1 extends FormFieldBaseInputProps | undefined = undefined,
  F2 extends FormFieldBaseInputProps | undefined = undefined,
  F3 extends FormFieldBaseInputProps | undefined = undefined,
  F4 extends FormFieldBaseInputProps | undefined = undefined,
  F5 extends FormFieldBaseInputProps | undefined = undefined
> = MultiFieldProps<F1, F2, F3, F4, F5> & {
  forwardedRef?: Ref<HTMLDivElement>;
};

/** MultiField provides a responsive layout for a row of related inputs.
 * Grouping related inputs together saves vertical space and helps users enter
 * data. It’s similar to FormField: where FormField provides a responsive
 * vertical layout, MultiField provides a responsive horizontal layout.
 *
 * MultiField is a generic component where you are encouraged to supply type
 * parameters in order get strong typing on each index of `fields`. Fallback
 * types exist to allow more flexible typing when `fields` is an array instead
 * of a tuple.
 */
function MultiField<
  F1 extends FormFieldBaseInputProps | undefined,
  F2 extends FormFieldBaseInputProps | undefined,
  F3 extends FormFieldBaseInputProps | undefined,
  F4 extends FormFieldBaseInputProps | undefined,
  F5 extends FormFieldBaseInputProps | undefined
>({
  className,
  fields,
  forwardedRef,
  hideRequiredStyles: hideRequiredStylesGlobal,
  hintText,
  id: upstreamId,
  labelAlwaysAbove,
  labelText,
  labelWidth,
  layout,
  nested,
  required,
  disabled,
  isLabelTextError = true,
  ...passThroughProps
}: MultiFieldComponentProps<F1, F2, F3, F4, F5>): ReactElement {
  const context = useContext(FormContext);
  // if name is not provided for a field, populate it with the field's id
  const fieldsWithNames = fields.map((field) => {
    return {
      name: field.name || field.id,
      ...field,
    };
  });

  const id = useMemo(() => (upstreamId !== undefined ? upstreamId : nanoid()), [upstreamId]);
  const labelId = `${id}-label`;

  const fieldsHaveLabels = fields.some((field) => !!field.labelText);
  const hideLabelContainers = !fieldsHaveLabels;

  const calculatedLabelWidth = labelText ? (labelAlwaysAbove ? 12 : labelWidth) : 0;

  const showRequiredLabel = useCallback(
    (hideRequiredStyles: boolean | undefined, required: boolean | undefined, context: FormContextData): boolean => {
      if (hideRequiredStyles) {
        return false;
      } else if (context.requiredVariation === 'blueBarWithRequiredLabel') {
        return !!required;
      } else {
        return false;
      }
    },
    []
  );

  const fieldErrors = fields.map((field, index) => {
    const fieldProps = fieldsWithNames[index];
    return fieldProps.error ? (
      <div key={`${fieldProps.name}-error`}>
        <FormError {...classes('form-error')} id={`${fieldProps.id}-input-error`}>
          <span>
            {fieldProps.labelText && isLabelTextError
              ? `${fieldProps.labelText}: ${fieldProps.error}`
              : `${fieldProps.error}`}
          </span>
        </FormError>
      </div>
    ) : undefined;
  });

  return (
    <div {...classes({ states: { disabled: !!disabled }, extra: className })} ref={forwardedRef}>
      <FormFieldLayout
        {...passThroughProps}
        aria-labelledby={labelId}
        id={id}
        labelWidth={calculatedLabelWidth}
        nested={nested}
        layout={layout}
        className={className}
        statusExtras={
          formFieldClasses({
            element: 'status',
            modifiers: 'below',
            removeBase: true,
          }).className
        }
        useFieldset={!!labelText}
        labelSlot={
          labelText && (
            <Label
              id={labelId}
              text={labelText}
              required={showRequiredLabel(hideRequiredStylesGlobal, required, context)}
              useLegend
              disabled={disabled}
            />
          )
        }
        messageSlot={hintText && <InlineMessage text={hintText} disabled={disabled} />}
        statusIndicatorSlot={fieldErrors}
        inputSlot={
          <div {...classes({ element: 'fields-container' })}>
            {fields.map((field, index) => {
              const { hideRequiredStyles, inputAs, ...fieldProps } = fieldsWithNames[index];
              return (
                <MultiFieldFieldComponent
                  context={context}
                  key={index}
                  showRequiredLabel={showRequiredLabel}
                  hideLabelContainers={hideLabelContainers}
                  hideRequiredStyles={hideRequiredStylesGlobal || hideRequiredStyles}
                  inputAs={inputAs as React.ComponentType<FormFieldBaseInputProps>}
                  {...fieldProps}
                />
              );
            })}
          </div>
        }
      />
    </div>
  );
}

const multiFieldPropTypes: WeakValidationMap<MultiFieldProps & { '...rest': unknown }> = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      className: optionalStringValidator,
      disabled: optionalBoolValidator,
      error: optionalStringValidator,
      hideRequiredStyles: optionalBoolValidator,
      id: PropTypes.string.isRequired,
      inputAs: PropTypes.elementType as Validator<React.ComponentType<FlexibleMultiFieldInputAsProps>>,
      labelText: optionalStringValidator,
      name: optionalStringValidator,
      required: optionalBoolValidator,
    }).isRequired
  ).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hideRequiredStyles: PropTypes.bool,
  hintText: PropTypes.string,
  isLabelTextError: PropTypes.bool,
  labelAlwaysAbove: PropTypes.bool,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  '...rest': PropTypes.any,
};

MultiField.propTypes = multiFieldPropTypes;

export default forwardRefToProps(MultiField) as <
  F1 extends FormFieldBaseInputProps | undefined = undefined,
  F2 extends FormFieldBaseInputProps | undefined = undefined,
  F3 extends FormFieldBaseInputProps | undefined = undefined,
  F4 extends FormFieldBaseInputProps | undefined = undefined,
  F5 extends FormFieldBaseInputProps | undefined = undefined
>(
  props: MultiFieldProps<F1, F2, F3, F4, F5>
) => ReactElement;
