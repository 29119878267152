import PropTypes from 'prop-types';
import React, {
  FocusEventHandler,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  Ref,
  WeakValidationMap,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import DollarSmall from '@athena/forge-icons/dist/DollarSmall';
import { ForgeIconComponent } from '@athena/forge-icons/dist/ForgeIconProps';

import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';
import InputRefContext from './InputRefContext';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /** Content to display inside the Input wrapper, after the input and optional Icon */
  children?: ReactNode;
  /** Adds a class to the root element of the component */
  className?: string;
  /** Adds a clear button to the right side of the input */
  clearable?: boolean;
  /** Adds a dollar sign icon, ignores non-numeric, accepts max. 2 decimal places, adds missing decimal point/places on blur */
  currency?: boolean;
  /** Determines the initial value of the input, but allows it to be updated ([uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)). */
  defaultValue?: InputHTMLAttributes<HTMLInputElement>['defaultValue'];
  /** Adds the relevant Forge state class to the input and passes prop to native react `<input />` */
  disabled?: boolean;
  /** When type="number", allows the mouse wheel to change the value. */
  enableNumericalScroll?: boolean;
  /** Highlights the input when defined (specific string doesn't matter) */
  error?: string;
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles?: boolean;
  /** Optionally display an icon in the left side of the Input. Provide an small
   * icon component from the @athena/forge-icons package.
   */
  icon?: ForgeIconComponent;
  /** @deprecated since 9.2.0
   *
   * Use ref instead.
   */
  inputRef?: Ref<HTMLInputElement>;
  /** A reference to the underlying <input> that is rendered */
  ref?: Ref<HTMLInputElement>;
  /** Indicates if it is a required field. Passed as an attribute to the native react `<input />` */
  required?: boolean;
  /** Value of the input. Creates a [controlled](https://reactjs.org/docs/forms.html#controlled-components) component. */
  value?: InputHTMLAttributes<HTMLInputElement>['value'];
}

export interface InputComponentProps extends InputProps {
  /** A reference to the underlying <input> that is rendered */
  forwardedRef?: Ref<HTMLInputElement>;
}

const classes = forgeClassHelper({ name: 'input' });

const semanticColorFontLight = 'font-light' as const;
const semanticColorFontDisabled = 'font-disabled' as const;

const InputIcon = ({ icon, disabled }: InputProps): JSX.Element | null => {
  if (typeof icon === 'function') {
    const ForgeIcon = icon;
    return (
      <ForgeIcon
        {...classes({ element: 'forge-icon', modifiers: { large: ForgeIcon.size === 'large' } })}
        aria-label="Input icon"
        semanticColor={disabled ? semanticColorFontDisabled : semanticColorFontLight}
      />
    );
  }
  return null;
};

/** Modifies event.target.value, making it consistent with a currency value. */
const handleChangeCurrencyText = (event: React.ChangeEvent<HTMLInputElement>): void => {
  let value = event.currentTarget.value;

  if (value !== null) {
    // Keep only valid number characters.
    value = value.replace(/[^\-0-9.]/g, '');
    // The first character may or may not be a negative sign but the following characters cannot.
    if (value.indexOf('-', 1) > 0) {
      // Remove extraneous negative signs.
      value = value.charAt(0) + value.substring(1).replace(/[^0-9.]/g, '');
    }
    const decimal = value.indexOf('.');

    // Snip off any thousandths decimal.  This also has the side-effect of stripping off any
    // extraneous decimal points.  Since the first one is being chosen, this may or may
    // not be what the end user wants.  This is really going to be hit on copy-paste.
    // Clearly, there is no rounding up.
    if (decimal !== -1 && value.length - decimal > 3) {
      value = '' + parseFloat(value.substring(0, decimal + 3)).toFixed(2);
    }

    // If the event value has changed, update the event and keep the cursor position.
    if (value !== event.currentTarget.value) {
      event.currentTarget.value = value;
    }
  }
};

const Input = ({
  type: upstreamType = 'text',
  children,
  className,
  clearable = false,
  currency = false,
  defaultValue,
  disabled = false,
  enableNumericalScroll = false,
  error,
  forwardedRef,
  hideRequiredStyles = false,
  icon: upstreamIcon,
  inputRef,
  required = false,
  size = 0,
  value,
  onChange,
  onBlur,
  onFocus,
  ...inputProps
}: InputComponentProps): ReactElement => {
  const usedDefaultValue = typeof defaultValue === 'number' ? String(defaultValue) : defaultValue;
  const [usedValue, setUsedValue] = useState(usedDefaultValue || '');
  const shownValue = value === undefined ? usedValue : value;
  const internalRef = useRef<HTMLInputElement>(null);
  // internal state to track clicking status on the clear icon
  const [focused, setFocused] = useState(false);
  const [wasFocused, setWasFocused] = useState(false);
  const [clearClicking, setClearClicking] = useState(false);
  const showClear = clearable && shownValue !== '';
  // If currency is true, the type must be 'text' and the icon must be DollarSmall.
  const type = currency ? 'text' : upstreamType;
  const icon = currency ? DollarSmall : upstreamIcon;
  const contextRef = useContext(InputRefContext);

  // using an internal ref and also forwarding it:
  // https://www.carlrippon.com/using-a-forwarded-ref-internally/
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(inputRef, () => internalRef.current);
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(forwardedRef, () => internalRef.current);
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(contextRef, () => internalRef.current);

  // don't pass through focus change events caused by clicking the clear button
  const handleFocus: FocusEventHandler<HTMLInputElement> = (evt) => {
    setFocused(true);
    if (onFocus && !clearClicking) {
      onFocus(evt);
    }
  };

  // don't pass through focus change events caused by clicking the clear button
  const handleBlur: FocusEventHandler<HTMLInputElement> = (evt) => {
    if (currency) {
      const value = parseFloat(evt.currentTarget.value);

      if (!isNaN(value)) {
        evt.currentTarget.value = '' + parseFloat(evt.currentTarget.value).toFixed(2);
        setUsedValue(evt.currentTarget.value);
      }
    }
    setFocused(false);
    if (onBlur && !clearClicking) {
      onBlur(evt);
    }
  };

  /** Disable mouse scroll for numerical inputs to prevent accidentally changing
   * form values.
   */
  useEffect(() => {
    const handleWheel: EventListener = (evt) => {
      evt.preventDefault();
    };
    const domNode = internalRef.current;
    if (domNode && type === 'number' && !enableNumericalScroll) {
      domNode.addEventListener('wheel', handleWheel, { passive: false });
      return () => {
        domNode.removeEventListener('wheel', handleWheel);
      };
    }
  }, [enableNumericalScroll, type]);

  return (
    <div
      {...classes({
        modifiers: {
          'with-icon': !!icon,
          'with-clear': showClear,
          required: required && !hideRequiredStyles && !disabled,
          hidden: contextRef !== null && type === 'file',
        },
        extra: className,
      })}
      // this function is a near-ish match for how an html input scales with size
      style={size > 0 ? { maxWidth: size / 2 + 1.5 + (icon ? 1.5 : 0) + (showClear ? 1.2 : 0) + 'em' } : {}}
    >
      <input
        {...inputProps}
        ref={internalRef}
        {...classes({
          element: 'input',
          states: {
            disabled: disabled,
            error: !!error && !disabled,
            required: required && !hideRequiredStyles && !disabled,
          },
        })}
        type={type}
        disabled={disabled}
        required={required}
        value={shownValue}
        onChange={(evt) => {
          if (currency) {
            handleChangeCurrencyText(evt);
          }
          setUsedValue(evt.currentTarget.value);
          if (onChange) {
            onChange(evt);
          }
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <InputIcon icon={icon} error={error} disabled={disabled} />
      {showClear && !disabled && (
        <CloseSmall
          {...classes({ element: 'clear-icon', states: [clearClicking ? 'active' : ''] })}
          semanticColor={semanticColorFontLight}
          title="Clear text"
          onPointerDown={(evt) => {
            // the test environment doesn't have setPointerCapture, so don't try.
            evt.currentTarget.setPointerCapture && evt.currentTarget.setPointerCapture(evt.pointerId);
            setWasFocused(focused);
            if (focused) {
              setClearClicking(true);
            }
          }}
          onPointerUp={(evt) => {
            // the test environment doesn't have releasePointerCapture, so don't try.
            evt.currentTarget.releasePointerCapture && evt.currentTarget.releasePointerCapture(evt.pointerId);
            if (!focused && internalRef.current && wasFocused) {
              internalRef.current.focus();
            }

            setClearClicking(false);
          }}
          onClick={(evt) => {
            // we captured the pointer, so now we have to check to see if the mouseUp was on the original target
            if (
              internalRef.current &&
              (!document.elementsFromPoint ||
                document.elementsFromPoint(evt.clientX, evt.clientY).findIndex((n) => n === evt.currentTarget) >= 0)
            ) {
              // clear the content "natively" to get React to fire off a change event.
              // https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
              )?.set;
              if (nativeInputValueSetter) {
                nativeInputValueSetter.call(internalRef.current, '');
                const inputEvent = new Event('input', { bubbles: true });
                internalRef.current.dispatchEvent(inputEvent);
              }
              internalRef.current.focus();
            }
          }}
        />
      )}
      {children}
    </div>
  );
};

Input.displayName = 'Input';

const inputPropTypes: WeakValidationMap<InputProps & { '...rest': unknown }> = {
  /** Content to display inside the Input wrapper, after the input and optional Icon */
  children: PropTypes.node,
  /** Adds a class to the root element of the component */
  className: PropTypes.string,
  /** Adds a clear button to the right side of the input */
  clearable: PropTypes.bool,
  /** Adds a dollar sign icon, ignores non-numeric, accepts max. 2 decimal places, adds missing decimal point/places on blur */
  currency: PropTypes.bool,
  /** Determines the initial value of the input, but allows it to be updated ([uncontrolled](https://reactjs.org/docs/uncontrolled-components.html)). */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Adds the relevant Forge state class to the input and passes prop to native react `<input />` */
  disabled: PropTypes.bool,
  /** When type="number", allows the mouse wheel to change the value. */
  enableNumericalScroll: PropTypes.bool,
  /** Highlights the input when defined (specific string doesn't matter) */
  error: PropTypes.string,
  /** Hides any required styling. Can be set manually or by the required field variation set for `Form`. */
  hideRequiredStyles: PropTypes.bool,
  /** Optionally display an icon in the left side of the Input. Provide an small
   * icon component from the @athena/forge-icons package.
   */
  icon: PropTypes.func,
  /** Function that takes a JS event as an argument. Called when the Input loses focus */
  onBlur: PropTypes.func,
  /** Function that takes a JS event as an argument. Called when the Input value changes */
  onChange: PropTypes.func,
  /** Function that takes a JS event as an argument. Called when the Input receives focus */
  onFocus: PropTypes.func,
  /** Indicates if it is a required field. Passed as an attribute to the native react `<input />` */
  required: PropTypes.bool,
  /** Type of text entry input to display */
  type: PropTypes.oneOf([
    'text',
    'number',
    'date',
    'datetime-local',
    'email',
    'file',
    'password',
    'search',
    'tel',
    'time',
    'url',
  ]),
  /** Value of the input. Creates a [controlled](https://reactjs.org/docs/forms.html#controlled-components) component. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Passthrough props to the `<input>` element */
  '...rest': PropTypes.any,
};
Input.propTypes = inputPropTypes;

export default forwardRefToProps(Input);
