import Input from '../../../Input';
import { createSortByNumber } from '../../sorts/createSortByNumber';
import { validate_row_data } from '../../store/actions/validate_row_data';
import { classes } from '../../utils/dataTableClasses';
import { DataTableColumn, CreateColumnCommonArgs } from '../../utils/internalTypes';

type CreateCurrencyColumnArgs<RowData> = Omit<CreateColumnCommonArgs<RowData>, 'displayCellText'> & {
  currency?: string; // Default: 'USD'. e.g. 'USD', 'EUR', ...
};
export type CreateCurrencyColumn<RowData> = typeof createCurrencyColumn<RowData>;

export function createCurrencyColumn<RowData>({
  columnId,
  tooltip,
  headerText,
  footer,
  cellValidation = () => '',
  determineCellAlert = () => '',
  rowDataFieldName,
  enableClientSideSorting = false,
  enableClientSideFullTextSearch = true,
  enableClientSideCsvDownload = true,
  isConfigurable = true,
  defaultHideColumn = false,
  //
  // additional args
  currency = 'USD',
}: CreateCurrencyColumnArgs<RowData>): DataTableColumn<RowData> {
  const headerTextArray = typeof headerText === 'string' ? [headerText] : headerText;

  const displayCellText = (row: RowData): string => {
    const value = row[rowDataFieldName];
    return typeof value !== 'number'
      ? '' // prettier-break-line
      : value.toLocaleString('en-US', { style: 'currency', currency });
  };

  return {
    columnId,
    configOptionName: isConfigurable ? headerTextArray.join(' ') : '',
    defaultHideColumn,

    // ================================
    // Header
    // ================================
    headerAlignRight: true,
    headerTooltip: tooltip,
    Header: () => (
      <>
        {headerTextArray.map((txt, idx) => (
          <div key={idx}>{txt}</div>
        ))}
      </>
    ),

    // ================================
    // Footer
    // ================================
    Footer: footer,

    // ================================
    // Cell
    // ================================
    cellStyle: () => ({ justifyContent: 'flex-end' }),
    Cell: ({ useSelector, rowId }) => {
      const rowData = useSelector((s) => s.rows[rowId].data);
      const cellText = displayCellText(rowData);
      return <>{cellText === '' ? '---' : cellText}</>;
    },

    // ================================
    // EditCell
    // ================================
    EditCell: ({ useDispatch, useSelector, rowId }) => {
      const dispatch = useDispatch();
      const cellError = useSelector((s) => s.rows[rowId].cellErrors[columnId]);
      const originalValue = useSelector((s) => s.rows[rowId].originalData[rowDataFieldName]);
      const value = useSelector((s) => s.rows[rowId].data[rowDataFieldName]);
      if (typeof value !== 'number' && value !== null)
        throw new Error(`The value (${value}) should be number type or null.`);

      return (
        <Input
          {...classes({ element: 'td__input', modifiers: { unsaved: value !== originalValue } })}
          currency={true}
          type="number"
          value={typeof value === 'number' ? value : ''}
          error={cellError}
          onBlur={() => dispatch(validate_row_data(rowId))}
          onChange={(e) => {
            dispatch(function update_currency_cell(s) {
              const value = e.target.value;
              const isEmpty = e.target.value === '';
              // @ts-expect-error The field type has already been verified in runtime
              s.rows[rowId].data[rowDataFieldName] = isEmpty ? null : Number(value);
            });
          }}
        />
      );
    },

    // ================================
    // Cell Alert
    // ================================
    determineCellAlert,

    // ================================
    // Data Validator
    // ================================
    dataValidator: (rowData) => {
      const fieldName = String(rowDataFieldName);
      const value = rowData[rowDataFieldName];

      if (typeof value !== 'number' && value !== null) {
        return `The '${fieldName}' field should be a number, null, or empty string.`;
      }
      if (Number.isNaN(value)) return `The '${fieldName}' field cannot be NaN.`;
      if (Number(value) < 0) return `The '${fieldName}' field should not be a negative number.`;
      return cellValidation(rowData);
    },

    // ================================
    // Sorting
    // ================================
    clientSideSortFn: enableClientSideSorting // prettier-break-line
      ? createSortByNumber({ rowDataFieldName })
      : undefined,

    // ================================
    // Full-Text Search
    // ================================
    clientSideFullTextSearch: {
      enable: enableClientSideFullTextSearch,
      getTextByRow: displayCellText,
    },

    // ================================
    // Download CSV
    // ================================
    clientSideCsvDownload: {
      enable: enableClientSideCsvDownload,
      headerText: headerTextArray.join(' '),
      getTextByRow: displayCellText,
    },
  };
}
