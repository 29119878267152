import { useEffect } from 'react';
import { DataTableDispatch } from '../store/stateTypes';
import { DataTableProps } from '../DataTable';
import { DEFAULT_CLIENT_SORT_FN } from '../store/stateTypes';

type ColumnPropReceiverProps<RowData> = {
  dispatch: DataTableDispatch<RowData>;
  columns: DataTableProps<RowData>['columns'];
};

export function ColumnPropReceiver<RowData>({
  dispatch, // prettier-break-line
  columns,
}: ColumnPropReceiverProps<RowData>): JSX.Element {
  const columnSet = new Set<string>();
  // check column ids
  for (const { columnId } of columns) {
    if (columnSet.has(columnId)) {
      throw new Error(`columnId should be unique, but '${columnId}' is duplicated.`);
    }
    columnSet.add(columnId);
  }

  useEffect(() => {
    dispatch(function update_columns(s) {
      s.columnIds = columns.map((c) => c.columnId);
      s.columns = {};

      for (const c of columns) {
        s.columns[c.columnId] = {
          columnId: c.columnId,

          // Header
          headerTooltip: c.headerTooltip,
          headerAlignRight: c.headerAlignRight ?? false,

          // Components
          Header: c.Header,
          Footer: c.Footer, // Footer is nullable
          Cell: c.Cell,
          EditCell: c.EditCell ?? c.Cell,
          DeleteCell: c.DeleteCell ?? c.Cell,

          dataValidator: c.dataValidator,
          determineCellAlert: c.determineCellAlert,
          isLoading: false,

          // cell style and class name
          cellStyle: c.cellStyle,
          cellClassName: c.cellClassName,

          // Sorting
          sort: {
            enable: false,
            direction: '',
            clientSideSortAscFn: DEFAULT_CLIENT_SORT_FN,
            clientSideSortFn: DEFAULT_CLIENT_SORT_FN,
          },

          // Column Config
          configOptionName: c.configOptionName ?? '',
          hide: c.defaultHideColumn ?? false,

          // Column Filter
          filter: {
            enable: false,
            isOpen: false,
            conditionValues: [],
            clientSideColumnFilterFn: undefined,
            serverSideQueryParamFn: undefined,
            ColumnFilterComponent: () => <></>,
          },

          // Full-Text Search
          clientSideFullTextSearch: {
            enable: false,
            getTextByRow: () => '',
          },

          // CSV Download
          clientSideCsvDownload: {
            enable: false,
            headerText: '',
            getTextByRow: () => '',
          },
        };

        /**
         * client-side sort
         *
         * Provide backward compatibility
         *
         * If user supplies value to deprecated "ascending" sort function via prop, update state/store value
         */
        if (typeof c.clientSideSortAscFn !== 'undefined') {
          s.columns[c.columnId].sort.enable = true;
          s.columns[c.columnId].sort.clientSideSortAscFn = c.clientSideSortAscFn;
        }

        /**
         * client-side sort
         *
         * If user supplies sorting function via prop, update state/store value
         */
        if (typeof c.clientSideSortFn !== 'undefined') {
          s.columns[c.columnId].sort.enable = true;
          s.columns[c.columnId].sort.clientSideSortFn = c.clientSideSortFn;
        }

        // client-side column filter
        if (typeof c.columnFilter?.clientSideColumnFilterFn !== 'undefined') {
          s.columns[c.columnId].filter.enable = true;
          s.columns[c.columnId].filter.clientSideColumnFilterFn = c.columnFilter.clientSideColumnFilterFn;
          s.columns[c.columnId].filter.ColumnFilterComponent = c.columnFilter.ColumnFilterComponent;
        }

        // server-side column filter
        if (typeof c.columnFilter?.serverSideQueryParamFn !== 'undefined') {
          s.columns[c.columnId].filter.enable = true;
          s.columns[c.columnId].filter.serverSideQueryParamFn = c.columnFilter.serverSideQueryParamFn;
          s.columns[c.columnId].filter.ColumnFilterComponent = c.columnFilter.ColumnFilterComponent;
        }

        // client-side full-text search
        if (typeof c.clientSideFullTextSearch !== 'undefined' && c.clientSideFullTextSearch !== null) {
          s.columns[c.columnId].clientSideFullTextSearch = c.clientSideFullTextSearch;
        }

        // client-side csv download
        if (typeof c.clientSideCsvDownload !== 'undefined' && c.clientSideCsvDownload !== null) {
          s.columns[c.columnId].clientSideCsvDownload = c.clientSideCsvDownload;
        }
      }

      // ==============================
      // column config options
      // ==============================
      s.columnConfig.defaultOptions = columns.map((c) => ({
        columnId: c.columnId,
        columnName: c.configOptionName ?? '',
        hideColumn: c.defaultHideColumn ?? false,
      }));

      s.columnConfig.configurableColumnIds = s.columnConfig.defaultOptions
        .filter((c) => c.columnName !== '')
        .map((c) => c.columnId);
    });
  }, [dispatch, columns]);

  return <></>;
}
