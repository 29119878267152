import { ForgeIconComponent } from '@athena/forge-icons/dist/ForgeIconProps';
import { StructuredOption, SelectSize } from '../Select';

/** Context used by Select custom components.
 *
 * Used when certain props are not forwarded to custom components by react-select.
 */

import { createContext, useContext } from 'react';

/** Data available to Select that does not get forwarded to custom components
 * through props.
 */
export interface SelectContextData {
  size: SelectSize;
  icon?: ForgeIconComponent;
  required: boolean;
  handleFocusedOptionData: (optionData: StructuredOption | null, isFocused?: boolean) => void;
  handleFocusedMultiValueData: (multiValueData: StructuredOption | null, isFocused?: boolean) => void;
}
/** Some props of react-select do not get forwarded to custom
 * components. Pass these props through in a context instead
 */
export const SelectContext = createContext<SelectContextData>({
  size: 'medium',
  icon: undefined,
  required: false,
  handleFocusedOptionData: () => {
    return;
  },
  handleFocusedMultiValueData: () => {
    return;
  },
});

/** Custom hook to access SelectContext */
export const useSelectContext = (): SelectContextData => useContext(SelectContext);
