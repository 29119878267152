import { createSortByNumber } from '../../sorts/createSortByNumber';
import { createSortByText } from '../../sorts/createSortByText';
import { DataTableColumn, CreateColumnCommonArgs } from '../../utils/internalTypes';

type CreateIdColumnArgs<RowData> = Omit<CreateColumnCommonArgs<RowData>, 'cellValidation'> & {
  idType: 'string' | 'number';
};
export type CreateIdColumn<RowData> = typeof createIdColumn<RowData>;

export function createIdColumn<RowData>({
  columnId,
  tooltip,
  headerText,
  footer,
  determineCellAlert = () => '',
  displayCellText,
  rowDataFieldName,
  enableClientSideSorting = false,
  enableClientSideFullTextSearch = true,
  enableClientSideCsvDownload = true,
  isConfigurable = true,
  defaultHideColumn = false,
  //
  // additional args
  idType,
}: CreateIdColumnArgs<RowData>): DataTableColumn<RowData> {
  const headerTextArray = typeof headerText === 'string' ? [headerText] : headerText;

  if (typeof displayCellText === 'undefined') {
    displayCellText = (row) => (row[rowDataFieldName] === null ? '' : String(row[rowDataFieldName]));
  }

  return {
    columnId,
    configOptionName: isConfigurable ? headerTextArray.join(' ') : '',
    defaultHideColumn,

    // ================================
    // Header
    // ================================
    headerAlignRight: idType === 'number',
    headerTooltip: tooltip,
    Header: () => (
      <>
        {headerTextArray.map((txt, idx) => (
          <div key={idx}>{txt}</div>
        ))}
      </>
    ),

    // ================================
    // Footer
    // ================================
    Footer: footer,

    // ================================
    // Cell
    // ================================
    cellStyle: () => (idType === 'number' ? { justifyContent: 'flex-end' } : undefined),
    Cell: ({ useSelector, rowId }) => {
      const rowData = useSelector((s) => s.rows[rowId].data);

      let renderText = '---';

      if (typeof rowData[rowDataFieldName] === 'number' && displayCellText) {
        renderText = displayCellText(rowData);
      } else if (typeof rowData[rowDataFieldName] === 'string' && displayCellText) {
        if (rowData[rowDataFieldName] !== '') {
          renderText = displayCellText(rowData);
        }
      }

      return <>{renderText}</>;
    },

    // ================================
    // Cell Alert
    // ================================
    determineCellAlert,

    // Read-only and no validator
    dataValidator: () => '',

    // ================================
    // Sorting
    // ================================
    clientSideSortFn: !enableClientSideSorting
      ? undefined
      : idType === 'number'
        ? createSortByNumber({ rowDataFieldName })
        : createSortByText({ rowDataFieldName, caseSensitive: true }),

    // ================================
    // Full-Text Search
    // ================================
    clientSideFullTextSearch: {
      enable: enableClientSideFullTextSearch,
      getTextByRow: displayCellText,
    },

    // ================================
    // Download CSV
    // ================================
    clientSideCsvDownload: {
      enable: enableClientSideCsvDownload,
      headerText: headerTextArray.join(' '),
      getTextByRow: displayCellText,
    },
  };
}
