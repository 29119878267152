import React from 'react';
import { classes } from '../../utils/dataTableClasses';
import { DataTableColumn } from '../../utils/internalTypes';
import Tooltip from '../../../Tooltip';

type DetermineRowAlertArgs<RowData> = { rowData: RowData; rowId: number };
type DetermineRowAlertResults = {
  rowAlertType?: 'attention' | 'critical' | 'new' | 'success';
  customStyle?: React.CSSProperties;
  tooltip?: string;
};

type CreateRowAlertColumnArgs<RowData> = {
  determineRowAlert: (args: DetermineRowAlertArgs<RowData>) => DetermineRowAlertResults;
};
export type CreateRowAlertColumn<RowData> = typeof createRowAlertColumn<RowData>;

export function createRowAlertColumn<RowData>(args?: CreateRowAlertColumnArgs<RowData>): DataTableColumn<RowData> {
  return {
    columnId: 'row-alert',

    // ================================
    // Header
    // ================================
    Header: () => <></>,

    // ================================
    // Cell
    // ================================
    Cell: ({ useSelector, rowId }) => {
      const tableId = useSelector((s) => s.tableId);
      const rowData = useSelector((s) => s.rows[rowId].data);
      const columns = useSelector((s) => s.columns);
      const columnIds = useSelector((s) => s.columnIds);
      const hasCellAlerts = columnIds
        .filter((cid) => !columns[cid].hide)
        .some((cid) => {
          const { determineCellAlert = () => '' } = columns[cid];
          return determineCellAlert(rowData) !== '';
        });

      // determine row alert
      let results: DetermineRowAlertResults = args?.determineRowAlert({ rowData, rowId }) ?? {};
      if (typeof results.rowAlertType === 'undefined' && hasCellAlerts) {
        results = { rowAlertType: 'attention' };
      }

      const { rowAlertType = '', customStyle, tooltip = '' } = results;

      return (
        <Tooltip
          id={`${tableId}-row-alert-tooltip-${rowId}`} // prettier-break-line
          placement="right"
          text={tooltip}
        >
          <div {...classes({ element: 'row_alert_column', modifiers: rowAlertType })} style={customStyle} />
        </Tooltip>
      );
    },
  };
}
