import { useEffect } from 'react';
import { CreateColumnCommonArgs, DataTableColumn, DataTableValueTextOption } from '../../utils/internalTypes';
import { classes } from '../../utils/dataTableClasses';
import { createSortByText } from '../../sorts/createSortByText';
import { validate_row_data } from '../../store/actions/validate_row_data';
import RadioGroup from '../../../RadioGroup';
import { DataTableAsyncAction } from '../../store/stateTypes';
import { AwaitableModal } from '../../alerts/AwaitableModal';

type CreateRadioGroupColumnArgs<RowData> = Omit<
  CreateColumnCommonArgs<RowData>,
  'cellValidation' | 'displayCellText'
> & {
  fetchOptions: () => Promise<DataTableValueTextOption[]>;
  /**
   * Use `DT.ColumnFilter.create*` to create columnFilter instance.
   * - `DT.ColumnFilter.createSelectColumnFilter`
   * - `DT.ColumnFilter.createSelectionListColumnFilter`
   */
  // Internal Dev Note:
  // The second type parameter `FilterConditionValue` should be set to `any` to
  // allow users to override it. Overwriting with the `unknown` type is not allowed.
  columnFilter?: DataTableColumn<RowData, any>['columnFilter']; // eslint-disable-line @typescript-eslint/no-explicit-any
};
export type CreateRadioGroupColumn<RowData> = typeof createRadioGroupColumn<RowData>;

export function createRadioGroupColumn<RowData>({
  columnId,
  tooltip,
  headerText,
  footer,
  determineCellAlert = () => '',
  rowDataFieldName,
  enableClientSideSorting = false,
  enableClientSideFullTextSearch = true,
  enableClientSideCsvDownload = true,
  isConfigurable = true,
  defaultHideColumn = false,
  //
  // additional args
  fetchOptions,
  columnFilter,
}: CreateRadioGroupColumnArgs<RowData>): DataTableColumn<RowData> {
  const headerTextArray = typeof headerText === 'string' ? [headerText] : headerText;
  const RadioOptionMap: Record<string, DataTableValueTextOption> = {};

  const displayCellText = (row: RowData): string => {
    const cellValue = String(row[rowDataFieldName]);
    const cellLabel = RadioOptionMap[cellValue]?.label;
    return cellLabel ?? '';
  };

  // ==================================
  // Async Action
  // ==================================
  const fetch_options_async: DataTableAsyncAction<RowData> = async (dispatch) => {
    dispatch(function before_fetching_radio_options(s) {
      s.columns[columnId].isLoading = true;
    });

    try {
      const options = await fetchOptions();
      for (const opt of options) {
        RadioOptionMap[opt.value] = opt;
      }
    } catch (e) {
      await AwaitableModal(dispatch, {
        alertType: 'attention',
        headerText: 'Fetch Options Failed',
        children: (e as Error).message,
      });
    }

    dispatch(function after_fetching_radio_options_success(s) {
      s.columns[columnId].isLoading = false;
    });
  };

  return {
    columnId,
    configOptionName: isConfigurable ? headerTextArray.join(' ') : '',
    defaultHideColumn,

    // ================================
    // Header
    // ================================
    headerAlignRight: false,
    headerTooltip: tooltip,
    Header: ({ useDispatchAsyncAction }) => {
      const dispatchAsyncAction = useDispatchAsyncAction();

      useEffect(() => {
        dispatchAsyncAction(fetch_options_async);
      }, [dispatchAsyncAction]);

      return (
        <>
          {headerTextArray.map((txt, idx) => (
            <div key={idx}>{txt}</div>
          ))}
        </>
      );
    },

    // ================================
    // Footer
    // ================================
    Footer: footer,

    // ================================
    // Cell
    // ================================
    Cell: ({ useSelector, rowId }) => {
      const rowData = useSelector((s) => s.rows[rowId].data);

      const isLoading = useSelector((s) => s.columns[columnId].isLoading);
      if (isLoading) return <>Loading the options...</>;

      const cellText = displayCellText ? displayCellText(rowData) : '';
      return <div>{cellText === '' ? '---' : cellText}</div>;
    },

    // ================================
    // EditCell
    // ================================
    EditCell: ({ useDispatch, useSelector, rowId }) => {
      const dispatch = useDispatch();
      const tableId = useSelector((s) => s.tableId);
      const cellError = useSelector((s) => s.rows[rowId].cellErrors[columnId]);
      const originalValue = useSelector((s) => s.rows[rowId].originalData[rowDataFieldName]);
      const value = useSelector((s) => s.rows[rowId].data[rowDataFieldName]);
      const isLoading = useSelector((s) => s.columns[columnId].isLoading);

      if (typeof value !== 'string') {
        throw new Error(`The value (${value}) should be string type.`);
      }
      if (isLoading) return <>Loading the options...</>;

      return (
        <RadioGroup
          id={`${tableId}-radio-group-column-${columnId}-${rowId}`}
          {...classes({ element: 'td__input', modifiers: { unsaved: value !== originalValue } })}
          value={value}
          options={Object.values(RadioOptionMap)}
          error={cellError}
          onBlur={() => dispatch(validate_row_data(rowId))}
          onChange={(e) => {
            dispatch(function update_radio_cell(s) {
              const value = e.target.value;

              if (typeof value !== 'string') {
                throw new Error(`The value (${value}) should be string type.`);
              }

              // @ts-expect-error The field type has already been verified in runtime
              s.rows[rowId].data[rowDataFieldName] = value;
              s.rows[rowId].cellErrors[columnId] = ''; // reset cell error
            });
          }}
        />
      );
    },

    // ================================
    // Cell Alert
    // ================================
    determineCellAlert,

    // ================================
    // Data Validator
    // ================================
    dataValidator: (rowData) => {
      const fieldName = String(rowDataFieldName);
      const value = rowData[rowDataFieldName];

      if (typeof value !== 'string') return `The '${fieldName}' field should be string type.`;
      if (!(value in RadioOptionMap)) return `${value} is not a valid option for the '${fieldName}' field.`;
      return '';
    },

    // ================================
    // Sorting
    // ================================
    clientSideSortFn: enableClientSideSorting
      ? createSortByText({ rowDataFieldName, caseSensitive: false })
      : undefined,

    // ================================
    // Column Filter
    // ================================
    columnFilter,

    // ================================
    // Full-Text Search
    // ================================
    clientSideFullTextSearch: {
      enable: enableClientSideFullTextSearch,
      getTextByRow: displayCellText,
    },

    // ================================
    // Download CSV
    // ================================
    clientSideCsvDownload: {
      enable: enableClientSideCsvDownload,
      headerText: headerTextArray.join(' '),
      getTextByRow: displayCellText,
    },
  };
}
